import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import classes from './Contacts.module.css';
import MapBlock from 'components/ContactsComponent/MapBlock/MapBlock';
import Information from 'components/Information/Information';

export default function Contacts() {
  useEffect(() => {
    document.title = 'АиСТ-С мото | Контакты для связи и заказа мотоциклов';
  }, []);
  return (
    <main className={classes.contacts__container}>
      <Helmet>
        {/* <title>Контакты | АиСТ-С</title> */}
        <meta
          name="keywords"
          content="купить мотоциклы Benda в саратове, купить мотоциклы ARIIC в саратове, аист-с мото, aist-c moto, Benda в саратове, Benda, аист-с мото в саратове, Бирюзова 22, BENDA, ARIIC, CHANGJIANG"
        />
        <meta
          name="description"
          content='Контакты - ООО "АиСТ-С" - официальный дилер мотоциклов BENDA.'
        />
      </Helmet>
      <Information/>
      <MapBlock />
    </main>
  );
}