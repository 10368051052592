import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import classes from './AboutCompany.module.css';
import AboutBlock from '../../components/aboutCompanyComponent/AboutBlock/AboutBlock'
import MainAdvantagesBenda from 'components/aboutCompanyComponent/MainAdvantagesBenda/MainAdvantagesBenda';
import MainAdvantagesARIIC from 'components/aboutCompanyComponent/MainAdvantagesARIIC/MainAdvantagesARIIC';
import MainAdvantagesChangjiang from 'components/aboutCompanyComponent/MainAdvantagesChangjiang/MainAdvantagesChangjiang';

export default function AboutCompany() {
  useEffect(() => {
    document.title = 'Официальный дилер BENDA | АиСТ-С мото';
  }, []);
  return (
    <main className={classes.about__container}>
      <Helmet>
        {/* <title>О Компании | АиСТ-С</title> */}
        <meta
          name="keywords"
          content='купить мотоциклы BENDA в саратове, купить мотоциклы ARIIC в саратове, купить мотоциклы CHANGJIANG в саратове, аист-с мото, aist-c moto, Benda в саратове, Benda, аист-с мото в саратове, Бирюзова 22, BENDA, ARIIC, CHANGJIANG'
        />
        <meta
          name="description"
          content='О Компании - ООО "АиСТ-С" - официальный дилер мотоциклов BENDA, ARIIC, CHANGJIANG'
        />
      </Helmet>
      <AboutBlock/>
      <MainAdvantagesBenda/>
      <MainAdvantagesARIIC/>
      <MainAdvantagesChangjiang/>
    </main>
  );
}