import React from 'react';
import classes from './InfoCompanyCards.module.css';
import OneInfoCard from './OneInfoCard/OneInfoCard';

export default function InfoCompanyCards() {
  const titles = [
    '1',
    '2',
    '3',
  ];

  const texts = [
    'Оставляете заявку, консультируетесь, рассчитываете стоимость доставки, оформляете покупку.',
    'Оплачиваете выставленный счет. Есть возможность оформить кредит или лизинг, узнайте подробнее.',
    'Получаете мотоцикл в своем городе и все документы купли-продажи, а также ПТС.',
  ];
  
  return (
    <div className={classes.main_info_cards__wrapper}>
        <span className={classes.info_cards_title}>Порядок оформления заказа</span>
        <div className={classes.info_cards__wrapper}>
        {titles.map((el, id) => {
          return <OneInfoCard title={titles[id]} text={texts[id]} key = {id}/>
        })}
        </div>
    </div>
    );
}