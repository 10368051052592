import React, { useContext, useRef } from 'react';
import './PaymentModal.css';
import { Context } from '../../Context/Context';
import Close from 'components/UI/Button/Close';
// import Close from '../UI/Button/Close';
// import Input from 'components/UI/Input/Input';
// import Swal from 'sweetalert2';
// import emailjs from 'emailjs-com';
// import modalImg from '../../assets/img/modalImg.png';

export default function PaymentModal() {
  const { state, dispatch } = useContext(Context);
  // const { formDispatch, state, dispatch } = useContext(Context);
  // const form = useRef<HTMLFormElement>(null);
  
  // const sendEmail = (event: React.FormEvent) => {
  //   // {/* TODO: раскомментировать всю функцию и 2 импорта*/}
  //   event.preventDefault();
  //   if (form.current) {
  //     emailjs
  //       .sendForm('service_ksceqzu', 'template_8d5afbm', form.current, 'a16Nmw3f7CqETrIRS')
  //       .then(
  //         (result) => {
  //           console.log(result.text);
  //           Swal.fire('Good job!', 'Ваша заявка успешно отправлена', 'success');
  //           resetForm();
  //         },
  //         (error) => {
  //           console.log(error.text);
  //           Swal.fire({
  //             icon: 'error',
  //             title: 'Oops...',
  //             text: 'Сервис временно недоступен. Попробуйте повторить запрос через несколько минут',
  //           });
  //         }
  //       );
  //   }
  // };
  // const checkNumber = () => {
  //   const reg = /^\+?[78][-\(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/;
  //   const valid = reg.test(String(formState.phoneNumber));
  //   if (!valid) {
  //     if (formDispatch) {
  //       formDispatch({
  //         type: 'errorNumber',
  //         payloadForm: { errorNumber: 'формат: 71234567890' },
  //       });
  //     }
  //   } else {
  //     if (formDispatch) {
  //       formDispatch({
  //         type: 'errorNumber',
  //         payloadForm: { errorNumber: '' },
  //       });
  //     }
  //   }
  // };
  // const checkEmail = () => {
  //   const reg =
  //     /^[a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-zA-Z0-9]@[a-zA-Z0-9][-\.]{0,1}([a-zA-Z][-\.]{0,1})*[a-zA-Z0-9]\.[a-zA-Z0-9]{1,}([\.\-]{0,1}[a-zA-Z]){0,}[a-zA-Z0-9]{0,}$/i;
  //   const valid = reg.test(String(formState.email));
  //   if (!valid) {
  //     if (formDispatch) {
  //       formDispatch({
  //         type: 'errorEmail',
  //         payloadForm: { errorEmail: 'неверный формат' },
  //       });
  //     }
  //   } else {
  //     if (formDispatch) {
  //       formDispatch({
  //         type: 'errorEmail',
  //         payloadForm: { errorEmail: '' },
  //       });
  //     }
  //   }
  // };
  
  // const resetForm = () => {
  //   if (formDispatch) {
  //     form.current?.reset();
  //     formDispatch({ type: 'model', payloadForm: { model: '' } });
  //     formDispatch({ type: 'name', payloadForm: { name: '' } });
  //     formDispatch({ type: 'phoneNumber', payloadForm: { phoneNumber: '' } });
  //     formDispatch({ type: 'email', payloadForm: { email: '' } });
  //     formDispatch({ type: 'message', payloadForm: { message: '' } });
  //     formDispatch({ type: 'errorNumber', payloadForm: { errorNumber: '' } });
  //     formDispatch({ type: 'errorEmail', payloadForm: { errorEmail: '' } });
  //     dispatch({ type: 'resetModal', payload: { isOpenModal: false } });
  //   }
  // };
  const changeModal = () => {
    if (state.isOpenPaymentModal) {
      if (dispatch) {
        dispatch({ type: 'resetPaymentModal', payload: { isOpenPaymentModal: false } });
      }
    } else {
      if (dispatch) {
        dispatch({ type: 'resetPaymentModal', payload: { isOpenPaymentModal: true } });
      }
    }
  };
  console.log(state.isOpenPaymentModal);
  return state.isOpenPaymentModal ? (
    <section className="modal_container">
      <span className="modal_close">
        <Close onClick={changeModal}></Close>
      </span>
      <div className="modal_form_wrapper">
        <form className="payform-tinkoff" name="payform-tinkoff" id="payform-tinkoff">
          <input className="payform-tinkoff-row" type="hidden" name="terminalkey" value="TinkoffBankTest"></input>
          <input className="payform-tinkoff-row" type="hidden" name="frame" value="false"></input>
          <input className="payform-tinkoff-row" type="hidden" name="language" value="ru"></input>
          <input className="payform-tinkoff-row" type="hidden" name="receipt" value=""></input>
          <input className="payform-tinkoff-row" type="text" placeholder="Сумма заказа" name="amount" required></input>
          <input className="payform-tinkoff-row" type="hidden" placeholder="Номер заказа" name="order"></input>
          <input className="payform-tinkoff-row" type="text" placeholder="Описание заказа" name="description"></input>
          <input className="payform-tinkoff-row" type="text" placeholder="ФИО плательщика" name="name"></input>
          <input className="payform-tinkoff-row" type="email" placeholder="E-mail" name="email"></input>
          <input className="payform-tinkoff-row" type="tel" placeholder="Контактный телефон" name="phone"></input>
          <input className="payform-tinkoff-row payform-tinkoff-btn" type="submit" value="Оплатить"></input>
        </form>
      </div>
    </section>
    
  ) : null
}
