import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import classes from './Main.module.css';
import InfoCompanyCards from '../../components/MainComponents/InfoCompanyCards/InfoCompanyCadrs';
import MainPhoto from 'components/MainComponents/MainPhoto/MainPhoto';
import OurProduct from 'components/MainComponents/OurProduct/OurProduct';
// import OurAdvantages from 'components/MainComponents/OurAdvantages/OurAdvantages';
// import Button from '../../components/UI/Button/Button';
// import DinamicCounter from '../../components/UI/DinamicCounter/DinamicCounter';
// import Loader from 'components/UI/Loader/Loader';

export default function Main() {
  useEffect(() => {
    document.title = 'Купить мотоцикл BENDA | АиСТ-С мото';
  }, []);
  return (
    <main className={classes.main__container}>
      <Helmet>
        {/* <title>Главная | АиСТ-С</title> */}
        <meta
          name="keywords"
          content='купить мотоциклы BENDA в саратове, аист-с мото, aist-c moto, Benda в саратове, Benda, аист-с мото в саратове, Бирюзова 22, BENDA, ARIIC, CHANGJIANG'
        />
        <meta
          name="description"
          content='ООО "АиСТ-С" - официальный дилер мотоциклов BENDA, ARIIC, CHANGJIANG. Гарантия ⑤ лет! ✓ В наличии на складе! Быстрая доставка по России ⛟. тел: ☎'
        />
      </Helmet>
      <MainPhoto/>
      <InfoCompanyCards />
      <OurProduct />
      {/* <OurAdvantages /> */}
      {/* <h3>Главная страница</h3> */}
      
      {/* <DinamicCounter number={555}/> */}
    </main>
  );
}