// import funrider125_1 from '../assets/img/Benda/funrider125/slider/DSC05799.jpg';
// import funrider125_2 from '../assets/img/Benda/funrider125/slider/DSC05806.jpg'
// import funrider125_3 from '../assets/img/Benda/funrider125/slider/DSC05808.jpg'
// import funrider125_4 from '../assets/img/Benda/funrider125/slider/DSC05811.jpg'
// import funrider125_5 from '../assets/img/Benda/funrider125/slider/DSC05812.jpg'
// import funrider125_6 from '../assets/img/Benda/funrider125/slider/DSC05813.jpg'
// import funrider125_7 from '../assets/img/Benda/funrider125/slider/DSC05814.jpg'
// import funrider125_8 from '../assets/img/Benda/funrider125/slider/DSC05815.jpg'
// import funrider125_9 from '../assets/img/Benda/funrider125/slider/DSC05815.jpg'
// import funrider125_10 from '../assets/img/Benda/funrider125/slider/DSC05816.jpg';
// import funrider125_11 from '../assets/img/Benda/funrider125/slider/DSC05817.jpg';
// import funrider125_12 from '../assets/img/Benda/funrider125/slider/DSC05818.jpg';
// import funrider125_13 from '../assets/img/Benda/funrider125/slider/DSC05819.jpg';
// import funrider125_14 from '../assets/img/Benda/funrider125/slider/DSC05823.jpg';
// import funrider125_15 from '../assets/img/Benda/funrider125/slider/DSC05829.jpg';
// import funrider125_16 from '../assets/img/Benda/funrider125/slider/DSC05833.jpg';
// import funrider125_17 from '../assets/img/Benda/funrider125/slider/DSC06137.jpg';
// import funrider125_18 from '../assets/img/Benda/funrider125/slider/DSC06541-1.jpg';
// import funrider125_19 from '../assets/img/Benda/funrider125/slider/DSC06696.jpg';
// import funrider125_20 from '../assets/img/Benda/funrider125/slider/DSC06700.jpg';
// import funrider125_21 from '../assets/img/Benda/funrider125/slider/DSC07924.jpg';
// import funrider125_22 from '../assets/img/Benda/funrider125/slider/DSC07939.jpg';
// import funrider125_23 from '../assets/img/Benda/funrider125/slider/DSC07966.jpg';
// import funrider125_24 from '../assets/img/Benda/funrider125/slider/DSC08103.jpg';
// import funrider125_25 from '../assets/img/Benda/funrider125/slider/DSC08124.jpg';

// const funrider = [funrider125_1, funrider125_2, funrider125_3, funrider125_4, funrider125_5, 
//                   funrider125_6, funrider125_7, funrider125_8, funrider125_9, funrider125_10,
//                   funrider125_10, funrider125_11, funrider125_12, funrider125_13, funrider125_14, funrider125_15, 
//                   funrider125_16, funrider125_17, funrider125_18, funrider125_19, funrider125_20,
//                   funrider125_21, funrider125_22, funrider125_23, funrider125_24, funrider125_25
//                 ];
// export default  funrider;

const imagefunrider = require.context('../assets/img/Benda/funrider125/slider', false);
const imagechinchilla = require.context('../assets/img/Benda/chinchilla300/slider', false);
const imagedarkflag = require.context('../assets/img/Benda/darkflag500/slider', false);
const imagelfc = require.context('../assets/img/Benda/lfc700/slider', false);
const imagenapoleon = require.context('../assets/img/Benda/Napoleon500/slider', false);
const imagerock = require.context('../assets/img/Benda/rock300', false);

const imageariic = require.context('../assets/img/ARIIC/ariic-318/slider', false);
const imageariictft = require.context('../assets/img/ARIIC/ariic-318-tft/slider', false);
const imageariicgemma = require.context('../assets/img/ARIIC/ariic-gemma-108/slider', false);

const imageadept = require.context('../assets/img/Changjiang/CJ Adept 700/slider', false);
const imageadeptsolo = require.context('../assets/img/Changjiang/CJ Adept 700 Solo/slider', false);
const imagedynasty = require.context('../assets/img/Changjiang/CJ Dynasty 700/slider', false);
const imagescarabey = require.context('../assets/img/Changjiang/CJ Скарабей 350/slider', false);
const imagetermit = require.context('../assets/img/Changjiang/CJ Термит 350/slider', false);

const funrider = imagefunrider.keys().map((img) => imagefunrider(img));
const chinchilla = imagechinchilla.keys().map((img) => imagechinchilla(img));
const darkflag = imagedarkflag.keys().map((img) => imagedarkflag(img));
const lfc = imagelfc.keys().map((img) => imagelfc(img));
const napoleon = imagenapoleon.keys().map((img) => imagenapoleon(img));
const rock = imagerock.keys().map((img) => imagerock(img));

const ariic = imageariic.keys().map((img) => imageariic(img));
const ariictft = imageariictft.keys().map((img) => imageariictft(img));
const ariicgemma = imageariicgemma.keys().map((img) => imageariicgemma(img));

const adept = imageadept.keys().map((img) => imageadept(img));
const adeptsolo = imageadeptsolo.keys().map((img) => imageadeptsolo(img));
const dynasty = imagedynasty.keys().map((img) => imagedynasty(img));
const scarabey = imagescarabey.keys().map((img) => imagescarabey(img));
const termit = imagetermit.keys().map((img) => imagetermit(img));

export {funrider};
export {chinchilla};
export {darkflag};
export {lfc};
export {napoleon};
export {rock};

export {ariic};
export {ariictft};
export {ariicgemma};

export {adept};
export {adeptsolo};
export {dynasty};
export {scarabey};
export {termit};