import React from 'react';
import classes from './OurProduct.module.css';
import { data } from '../../../data/data';
import { useNavigate } from 'react-router';

export default function OurProduct() {
    const navigate = useNavigate();
    return (
    <div className={classes.our_products__wrapper}>
        <span className={classes.our_products__title}>Наша продукция</span>
        <div className={classes.catalog_cards__container}>
            {data.map((el) => {
            return (
                <div
                key={el.title}
                className={classes.catalog__card}
                onClick={() => navigate(`/catalog/${el.url}`)}
                >
                <span className={classes.catalog__card_title}>{el.title}</span>
                {/* <img src={el.img} alt={el.title} className={classes.catalog__card_img} /> */}
                </div>
            );
            })}
        </div>
    </div>
    );
}